#root,
.App,
.AppContainer,
.Login .ant-row {
	height: 100%;
}
.App {
	font-size: 1.2em;
	width: 100%;
}
.AppContainer {
	margin: 0 auto;
	width: 100%;
	max-width: 1320px;
	padding: 18px 20px;
}
.SectionHero {
	width: 100%;
	min-height: 650px;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: flex-end;
	background-position: center;
	position: relative;
	padding: 0 0 50px;
}
@media screen and (min-width: 768px) {
	.SectionHero {
		min-height: 780px;
		padding: 0 0 120px;
	}
}
@media screen and (min-width: 1600px) {
	.SectionHero {
		min-height: 880px;
	}
}
.SectionHero:before {
	content: '';
	background: linear-gradient(13deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5) 20%, rgba(255,255,255,0) 100%);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.SectionHero:after {
	content: '';
	background: url(assets/images/hero_clip.svg) no-repeat center bottom;
	background-size: 100% auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: -2px;
	width: 100%;
	height: calc(100% + 2px);
}
.SectionHero .AppContainer {
	z-index: 2;
}
/* .SectionHero .AppContainer:after {
	content: '';
	background: url(assets/images/anniversary_40.png) no-repeat center bottom;
	background-size: contain;
	width: 60px;
	height: 60px;
	position: absolute;
	left: 50%;
	transform: translate(0,-50%);
	bottom: -30px;
}
@media screen and (min-width: 768px) {
	.SectionHero .AppContainer:after {
		width: 80px;
		height: 80px;
		bottom: -20px;
	}
}
@media screen and (min-width: 992px) {
	.SectionHero .AppContainer:after {
		width: 100px;
		height: 100px;
		bottom: 0;
	}
} */
.SectionHero .HeroTitle h1,
.SectionHero .HeroTitle h2 {
	color: #fff;
	font-size: 40px;
	margin: 0;
	text-transform: uppercase;
	line-height: 42px;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	font-weight: 500;
}
.SectionHero .HeroTitle h2 {
	font-size: 24px;
	line-height: 28px;
}
.SectionHero .HeroDesc {
	margin: 0 0 20px;
}
.SectionHero .HeroDesc p {
	color: #fff;
	font-size: 16px;
	line-height: 22px;
	margin: 15px 0;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 992px) {
	.SectionHero .HeroTitle h1,
	.SectionHero .HeroTitle h2 {
		font-size: 60px;
		line-height: 62px;
	}
	.SectionHero .HeroTitle h2 {
		font-size: 30px;
		line-height: 34px;
	}
	.SectionHero .HeroDesc p {
		font-size: 18px;
		line-height: 28px;
		margin: 20px 0;
		max-width: 90%;
	}
}
.SiteSection {
	padding: 60px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	width: 100%;
	overflow: hidden;
}
@media screen and (min-width: 992px) {
	.SiteSection {
		padding: 100px 0;
	}
}
.SiteSection.Dark {
	color: #fff;
	display: flex;
	align-items: center;
}
@media screen and (min-width: 768px) {
	.SiteSection.Dark.BottomBg {
		padding: 100px 0 264px;
	}
}
.SiteSection.Dark.BottomBg:before {
	content: '';
	background: linear-gradient(45deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.5) 50%, rgba(255,255,255,0) 100%);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.SiteSection.Dark.BottomBg:after {
	content: '';
	background: url(assets/images/section_clip.svg) no-repeat center bottom;
	background-size: 100% auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: -2px;
	width: 100%;
	height: calc(100% + 2px);
}
.SiteSection.Dark .AppContainer {
	z-index: 2;
	position: relative;
}
.SiteSection.Dark.CTA {
	padding: 60px 0;
	text-align: center;
}
.SiteSection.Dark.CTA:before {
	content: '';
	background: rgba(0,0,0,0.35);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.SiteSection.Dark.CTA .SectionTitle {
	margin-bottom: 0;
}
.SiteSection.Dark.CTA .SectionTitle p {
	max-width: 475px;
	margin-left: auto;
	margin-right: auto;
}
.SiteSection.Dark.CTA .SectionTitle .ant-flex {
	margin-top: 30px;
}
.SectionTitle {
	font-size: 20px;
	margin: 0 0 20px;
}
.SiteSection.Events .SectionTitle {
	margin-bottom: 50px;
}
.SectionTitle h2,
.SectionTitle h3 {
	text-transform: uppercase;
}
.SiteSection.Dark .SectionTitle h2,
.SiteSection.Dark .SectionTitle h3,
.SiteSection.Dark p {
	color: #fff;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.SectionTitle h2 {
	font-size: 30px;
	line-height: 32px;
	color: var(--color-secondary);
	margin: 0;
}
h2.SectionTitle {
	color: #8c94a4;
	font-size: 26px;
	margin-bottom: 30px;
}
.SectionTitle h3 {
	font-size: 18px;
	line-height: 20px;
	margin: 0 0 4px;
	color: var(--color-primary);
}
@media screen and (min-width: 768px) {
	.SectionTitle h2 {
		font-size: 40px;
		line-height: 42px;
	}
	.SectionTitle h3 {
		font-size: 20px;
		line-height: 22px;
	}
	h2.SectionTitle {
		font-size: 32px;
	}
}
.ImageWrap {
	position: relative;
	display: flex;
}
.ImageWrap img,
.ImageWrap:before,
.ImageWrap:after {
	border-radius: 25px;
}
.ImageWrap:before,
.ImageWrap:after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}
.ImageWrap:before {
	background: rgba(var(--color-primary-rgb), 0.5);
	z-index: -2;
	right: 0;
	bottom: 0;
	top: 10px;
	left: 10px;
}
.ImageWrap:after {
	background: var(--color-primary);
	z-index: -1;
	top: 5px;
	left: 5px;
}
.CheckList,
.SectionContent ul,
.PageSingle .AppContainer ul:not(.InfoMenu ul, .SchoolStopList ul, .suggestions) {
	font-size: 18px;
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 0 0 10px;
	list-style: none;
}
.CheckList li,
.SectionContent ul li {
	width: 50%;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
}
.PageSingle .AppContainer ul:not(.InfoMenu ul, .SchoolStopList ul, .suggestions) li {
	width: 100%;
	margin-bottom: 12px !important;
	display: flex;
}
@media screen and (min-width: 1280px) {
	.CheckList li,
	.SectionContent ul li {
		width: 33.33%;
	}
}
.CheckList li:before,
.SectionContent ul li:before,
.PageSingle .AppContainer ul:not(.InfoMenu ul, .SchoolStopList ul, .suggestions) li:before {
	content: '';
	width: 22px;
	height: 22px;
	background: url(assets/icons/Check.svg) no-repeat center;
	background-size: contain;
	display: flex;
	flex-shrink: 0;
	margin-right: 15px;
	opacity: 0.8;
}
.PageSingle .AppContainer ul:not(.InfoMenu ul, .SchoolStopList ul, .suggestions) li:before {
	margin-top: 3px;
}
.CardItem {
	background: none;
	box-shadow: none !important;
	border-radius: 0;
}
.CardItem .ant-card-cover {
	position: relative;
	display: block;
	margin-bottom: 25px;
}
.CardItem .ant-card-cover img,
.CardItem .ant-card-cover:after {
	border-radius: 25px;
}
.CardItem .ant-card-cover:after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}
.CardItem .ant-card-cover:after {
	background: var(--color-primary);
	z-index: -1;
	top: 5px;
	left: 5px;
}
.CardItem .ant-card-cover img {
	height: 100%;
  width: 100%;
	object-fit: cover;
}
.CardItem .ant-card-cover {
	height: 420px;
}
.CardItem .ant-card-body {
	padding: 0;
}
.CardItem h3 {
	margin: 0 0 8px;
	font-size: 17px;
	line-height: 21px;
}
@media screen and (min-width: 768px) {
	.CardItem h3 {
		font-size: 20px;
		line-height: 28px;
	}
}
.CardItem iframe {
	max-width: 100%;
	border-radius: 18px;
	overflow: hidden;
}
.PageSingle a {
	color: var(--color-secondary);
}
.PageSingle a:hover {
	text-decoration: underline;
}
.PageSingle .CardItem:not(.Cover) {
	background: #fff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02) !important;
	border-radius: 20px;
}
.PageSingle .CardItem .ant-card-body {
	padding: 30px;
}
.PageSingle .CardItem.Cover .ant-card-body {
	padding: 0;
}
.PageHero {
	width: 100%;
	background: var(--color-secondary);
	text-align: center;
	color: #fff;
	min-height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
}
.PageHero h1 {
	color: #fff;
	text-transform: uppercase;
}
.PageHero.Cover {
	width: 100%;
	min-height: 360px;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	background-position: center;
	position: relative;
	padding: 0;
}
.PageHero.Cover .AppContainer {
	z-index: 2;
}
.PageHero.Cover:before {
	content: '';
	background: linear-gradient(13deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.5) 20%, rgba(255,255,255,0) 100%);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.EventsSingle .PageHero {
	min-height: 200px;
}
.EventsSingle .PageHero h1 {
	text-transform: none !important;
	max-width: 800px;
	margin: 0 auto !important;
}
.CardItem p,
.PageSingle .CardItem ul,
.PageSingle .CardItem ol {
	color: var(--color-text);
	font-weight: 300;
	margin: 0 0 15px;
}
.CardItem .ant-card-body .ant-btn {
	margin-top: 10px;
}
.CardMeta {
	color: rgba(var(--color-text-rgb), 0.7);
	font-size: 16px;
	display: flex;
	align-items: center;
}
.CardItem.CardTable .ant-card-body {
	padding: 4px !important;
}
.ant-btn {
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 21px;
	box-shadow: none;
	color: var(--color-secondary);
	border-color: var(--color-secondary);
	font-weight: 500;
	border-radius: 50px !important;
	padding: 15px 30px;
	min-height: 54px;
	background: rgba(255,255,255,0.2);
	text-transform: uppercase;
	letter-spacing: 0.6px;
}
@media screen and (min-width: 768px) {
	.ant-btn {
		font-size: 17px !important;
	}
}
.SectionHero .ant-btn,
.SiteSection.Dark .ant-btn {
	color: #fff;
	border-color: #fff;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.ant-btn.ant-btn-sm:hover,
.ant-btn:hover {
	background: var(--color-secondary);
	color: #fff !important;
	border-color: var(--color-secondary) !important;
}
.ant-btn.ant-btn-primary {
	background: var(--color-primary);
	border-color: var(--color-primary);
	color: #fff;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}
.Employment.PageSingle a.ant-btn {
	background: var(--color-secondary) !important;
	color: #fff !important;
	border-color: var(--color-secondary) !important;
}
.Employment.PageSingle a.ant-btn:hover {
	background: var(--color-primary) !important;
	border-color: var(--color-primary) !important;
	color: #fff !important;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.1) !important;
}
.ant-btn.ant-btn-primary:hover {
	background: var(--color-secondary) !important;
	border-color: var(--color-secondary) !important;
}
.ant-btn.ant-btn-sm {
	font-size: 14px !important;
	display: inline-flex;
	align-items: center;
	line-height: 16px;
	min-height: 39px;
	padding: 12px 20px;
	border-color: rgba(var(--color-text-rgb), 0.8);
	color: rgba(var(--color-text-rgb), 0.8);
}
.ant-picker-ok .ant-btn.ant-btn-sm {
	background: var(--color-secondary);
	color: #fff;
	padding: 4px 10px;
	min-height: auto;
	height: auto;
}
.ant-picker-now .ant-picker-now-btn,
.ant-picker-footer .ant-picker-today-btn {
	font-size: 16px;
	color: var(--color-secondary);
}
.ant-btn.ant-btn-sm:hover svg path {
	stroke: #fff;
}
.CardItem.CardSteps .ant-card-body {
	padding-bottom: 0;
}
.PageSingle h2.title {
	margin: 0 0 8px;
	font-size: 18px !important;
	line-height: 20px;
	text-transform: uppercase;
}
.PageSingle h1.title {
	margin: 0;
	font-size: 30px !important;
	line-height: 32px;
	text-transform: uppercase;
}
.PageSingle .CardMeta {
	margin: 12px auto 0;
	color: rgba(255, 255, 255, 0.7);
	text-align: center;
	justify-content: center;
}
.PageSingle .CardItem p,
.PageSingle .CardItem ul,
.PageSingle .CardItem ol {
	font-size: 16px;
	line-height: 26px;
	margin: 0 0 20px;
}
.PageSingle .CardItem p:last-of-type {
	margin-bottom: 0;
}
.PageSingle .CardItem .CardTitle {
	margin-top: 0;
}
.PageSingle .CardItem ul li,
.PageSingle .CardItem ol li {
	margin-bottom: 5px;
}
.PageSingle .CardItem h1,
.PageSingle .CardItem h2,
.PageSingle .CardItem h3,
.PageSingle .CardItem h4,
.PageSingle .CardItem h5,
.PageSingle .CardItem h6 {
	color: var(--color-text);
}
.PageSingle .CardItem h1 {
	font-size: 20px;
}
.PageSingle .CardItem h2 {
	font-size: 19px;
}
.PageSingle .CardItem h3 {
	font-size: 18px;
}
.PageSingle .CardItem h4 {
	font-size: 17px;
}
.PageSingle .CardItem h5 {
	font-size: 16px;
}
.PageSingle .CardItem h6 {
	font-size: 15px;
}
.PageSingle {
	padding: 0 0 40px;
}
@media screen and (min-width: 768px) {
	.PageSingle {
		padding: 0 0 50px;
	}
	.PageSingle .CardItem .ant-card-cover {
		height: auto;
	}
	.PageSingle h2.title {
		font-size: 20px !important;
		line-height: 20px;
	}
	.PageSingle h1.title {
		font-size: 40px !important;
		line-height: 40px;
	}
	.PageSingle .CardItem p,
	.PageSingle .CardItem ul,
	.PageSingle .CardItem ol {
		font-size: 18px;
		line-height: 30px;
		margin: 0 0 25px;
	}
	.PageSingle .CardItem h1 {
		font-size: 24px;
	}
	.PageSingle .CardItem h2 {
		font-size: 22px;
	}
	.PageSingle .CardItem h3 {
		font-size: 20px;
	}
	.PageSingle .CardItem h4 {
		font-size: 18px;
	}
	.PageSingle .CardItem h5 {
		font-size: 16px;
	}
}
.PageSingle .ant-card.CardItem .ant-card-body img {
	min-width: 100%;
	max-width: 100%;
	height: auto;
}
.PageSingle .ant-card.CardItem .ant-card-body hr {
	border-top: 1px solid #ccc;
	margin: 30px 0 25px;
}
.AppFooter {
	position: relative;
}
.AppFooter .SiteFooter {
	background: #fff;
	position: relative;
	padding: 20px 0 0;
}
.AppFooter .SiteFooter:after {
	content: '';
	background: url(assets/images/footer_clip.svg) no-repeat center bottom;
	background-size: 100% auto;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: calc(100% + 30px);
	z-index: 2;
}
.AppFooter .SiteFooter .ant-flex a {
	width: fit-content;
}
.AppFooter .SiteFooter .ant-flex p {
	margin-top: 0;
	max-width: 360px;
	color: var(--color-text);
	font-size: 16px;
	line-height: 26px;
}
.AppFooter .SiteFooter .ant-col.ant-col-lg-6:last-of-type {
	padding-top: 45px;
}
.AppFooter .AppContainer {
	z-index: 3;
	position: relative;
}
.AppFooter .CopyFooter {
	background: #F2F6F8;
	padding: 200px 0 120px;
	margin-top: -200px;
	z-index: 1;
	position: relative;
}
.AppFooter .CopyFooter a {
	font-size: 14px;
	color: rgba(var(--color-text-rgb), 0.6);
}
.AppFooter .CopyFooter a:hover {
	text-decoration: underline;
}
.AppFooter .CopyFooter .AppContainer small:last-of-type a {
	color: #6b54f9;
}
@media screen and (min-width: 992px) {
	.AppFooter .SiteFooter {
		padding: 40px 0 0;
	}
	.AppFooter .SiteFooter:after {
		height: calc(100% + 210px);
	}
	.AppFooter .CopyFooter {
		padding: 420px 0 60px;
		margin-top: -250px;
	}
	.AppFooter .CopyFooter .AppContainer {
		display: flex;
	}
	.AppFooter .CopyFooter .AppContainer small:last-of-type {
		text-align: right;
	}
}
.AppFooter small {
	width: 100%;
	display: inline-block;
	padding: 30px 0 15px;
	color: rgba(var(--color-text-rgb), 0.6);
	font-size: 14px;
	line-height: 22px;
	font-weight: 300 !important;
}
@media screen and (max-width: 991px) {
	.FlexSM {
		flex-direction: column;
	}
	.AppFooter small:last-of-type {
		padding: 0;
	}
}
.AppAlert {
	background: var(--color-secondary);
	text-align: center;
	color: #fff;
	font-size: 16px;
	line-height: 22px;
	padding: 10px 20px 10px 40px;
	position: fixed;
	z-index: 99;
	bottom: 0;
	width: 100%;
}
.AppAlert .ant-alert-message p {
	margin: 0;
}
.AppAlert .ant-alert-message a {
	color: #fff;
	text-decoration: underline;
}
.AppAlert .ant-alert-message a:hover {
	text-decoration: none;
}
@media screen and (min-width: 768px) {
	.AppAlert {
		font-size: 22px;
		line-height: 26px;
		padding: 15px 20px 15px 40px;
	}
}
.SocialIcons {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	gap: 32px;
}
@media screen and (max-width: 991px) {
	.SocialIcons {
		margin-bottom: 40px;
	}
	.FooterLinks {
		margin-bottom: 40px;
	}
}
.SocialIcons li a:hover svg path {
	fill: var(--color-secondary);
}
.FooterLinks h4 {
	font-size: 16px;
	color: #98A2B3;
	margin: 0 0 15px;
}
.FooterLinks ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.FooterLinks ul li {
	margin: 0 0 12px;
}
.FooterLinks ul li:last-child {
	margin-bottom: 0;
}
.FooterLinks ul li a {
	color: var(--color-text);
	font-size: 16px;
	line-height: 26px;
}
.FooterLinks ul li a:hover {
	color: var(--color-secondary);
}
@media screen and (min-width: 540px) {
	.FooterLinks.FlexCol ul {
		display: flex;
		flex-wrap: wrap;
	}
}
.FooterLinks.FlexCol ul li {
	width: 50%;
}
.ant-form input,
.ant-form textarea,
.ant-form .ant-select-selection-item,
.ant-select-dropdown .ant-select-item-option-content {
	color: var(--color-text);
}
.ant-form .ant-form-item .ant-form-item-row .ant-form-item-label label,
.ant-form .ant-radio-group .ant-radio-wrapper span {
	color: var(--color-text);
	font-size: 16px;
}
.ant-form h4:not(:first-of-type) {
	margin-top: 50px;
}
.ant-form h4 {
	color: rgba(var(--color-text-rgb), 0.9) !important;
}
.ant-form h5 {
	color: rgba(var(--color-text-rgb), 0.8) !important;
}
.ant-btn.ant-btn-sm.btn-secondary {
	background: var(--color-secondary) !important;
	color: #fff !important;
	border: none;
	min-height: 44px;
	padding: 10px 40px;
	font-size: 15px !important;
}

.ant-btn.ant-btn-sm.btn-secondary:hover {
	background: var(--color-primary) !important;
}
.ant-form .ant-btn.ant-btn-sm.ant-btn-dashed {
	text-transform: none;
	min-height: 38px;
	margin-top: 0;
}
.ant-form .form-custom-labels .ant-form-item-control-input-content > div {
	width: 100%;
	display: flex;
	align-items: center;
}
.ant-form .form-custom-labels .ant-form-item-control-input-content > div:not(:last-of-type) {
	margin-bottom: 10px;
}
.ant-form .form-custom-labels .ant-form-item-control-input-content > div > span {
	border: 1px solid #d9d9d9;
	height: 40px;
	display: inline-flex;
	align-items: center;
	width: 20%;
	min-width: 50px;
	font-size: 16px;
	border-radius: 6px 0 0 6px;
	background: #f6f6f6;
	color: #777;
	padding: 0 0 0 10px;
}
.ant-form .form-custom-labels .ant-form-item-control-input-content > div > .ant-picker {
	border-radius: 0 !important;
	margin-left: -1px;
	width: 40%;
}
.ant-form .form-custom-labels .ant-form-item-control-input-content > div > .ant-picker:last-of-type {
	border-radius: 0 6px 6px 0 !important;
}
.SchoolStopList ul {
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}
.SchoolStopList ul li {
	width: 100%;
	margin: 0 !important;
}
@media screen and (min-width: 768px) {
	.SchoolStopList ul li {
		width: calc(50% - 10px);
	}
}
@media screen and (min-width: 1240px) {
	.SchoolStopList ul li {
		width: calc(33.33% - 20px);
	}
}
.SchoolStopList ul li a {
	color: #565f71;
	font-size: 18px;
	font-weight: 400;
	width: 100%;
	background: #fff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02) !important;
	border-radius: 20px;
	padding: 24px 22px;
	display: flex;
	gap: 20px;
	align-items: center;
}
.SchoolStopList ul li a:before {
	content: '';
	background: url('assets/icons/School.svg') no-repeat;
	background-size: contain;
	width: 31px;
	height: 26px;
	display: block;
	opacity: 0.6;
}
.SchoolStopList ul li a:hover {
	color: var(--color-secondary);
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.06), 0 2px 7px 0px rgba(0, 0, 0, 0.04), 0 3px 5px 0 rgba(0, 0, 0, 0.04) !important;
}
.SchoolStopList ul li a svg {
	display: none !important;
}
#busable_timetables .Timetable {
	padding-top: 50px;
}
#busable_timetables .Timetable h1.page-title.header-title {
	font-size: 24px;
	margin-bottom: 20px !important;
}
#busable_timetables .Timetable .map-items.RouteListContainer {
	min-height: 100% !important;
}
#busable_timetables .Timetable .ant-btn {
	background: #e9e6fa !important;
	color: #6b54f9 !important;
	border: none !important;
	font-size: 12px !important;
	padding: 0 15px !important;
	line-height: 16px !important;
	display: inline-flex !important;
	align-items: center !important;
	box-shadow: none!important;
	text-shadow: none!important;
	min-height: 28px !important;
}
.CardRegion:hover {
	text-decoration: none !important;
}
.CardRegion:hover .CardItem {
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.06), 0 3px 8px 0px rgba(0, 0, 0, 0.04), 0 4px 6px 0 rgba(0, 0, 0, 0.04) !important;
}
.CardRegion .CardItem .ant-card-body {
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
}
.CardRegion .CardItem .ant-card-body h2 {
	font-size: 22px;
	line-height: 40px;
	margin-bottom: 10px;
	margin-top: 0;
}
@media screen and (min-width: 1024px) {
	.CardRegion .CardItem .ant-card-body h2 {
		font-size: 28px;
		line-height: 40px;
	}
}
.Townlink .CardRegion .CardItem .ant-card-body h2 {
	margin: 0;
}
.CardRegion .CardItem .ant-card-body svg {
	width: 70px;
	height: 70px;
	opacity: 0.7;
}
.CardRegion:hover .CardItem .ant-card-body svg {
	opacity: 0.9;
}
.CardRegion:hover .CardItem .ant-card-body h2 {
	color: var(--color-secondary);
}
.CardItem .ant-alert {
	font-size: 18px;
	text-align: center;
	font-weight: 400;
}
.CardItem .ant-alert.ant-alert-success {
	color: #047216;
}
.CardItem .ant-collapse .ant-collapse-header {
	align-items: center;
}
.CardItem .ant-collapse .ant-collapse-header-text {
	font-size: 18px;
	font-weight: 400;
	color: var(--color-text);
}
.ant-btn.ant-btn-primary.ant-btn-sm.light {
	color: #fff;
	border: none;
}
.CardEmbed {
	background: none;
	margin-top: 20px;
	box-shadow: none !important;
}
.CardEmbed .ant-card-body {
	padding: 0;
}
.share-btn-wrap .icon-button.btn-outline {
	border-radius: 50px !important;
	background: none !important;
	color: #6b54f9 !important;
	border: 1px solid #6a54f942 !important;
	font-size: 11px !important;
	height: 25px !important;
	font-weight: 600 !important;
	text-transform: uppercase !important;
	align-items: center !important;
	display: flex !important;
	gap: 6px !important;
}
.share-btn-wrap .icon-button.btn-outline:hover {
	background: #6b54f9 !important;
	color: #fff !important;
}
.share-btn-wrap .icon-button.btn-outline svg {
	height: 12px !important;
}
.TripPlanner a {
	font-size: 14px !important;
}
.SchoolStopList.SchoolViewList .filter-options-main.top-float {
	display: none !important;
}
.InfoText {
	background: #fff;
	border-radius: 50px;
	text-align: center;
	padding: 15px 30px;
	font-size: 16px;
	margin: 80px 0;
	display: inline-block;
	color: #888;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
.ContactUs.PageSingle .AppContainer .CardItem .ant-row .ant-col:first-of-type ul {
	gap: 0 30px;
}
.ContactUs.PageSingle .AppContainer .CardItem .ant-row .ant-col:first-of-type ul li {
	width: auto;
}
.ContactUs.PageSingle .AppContainer .CardItem .ant-row .ant-col:last-of-type iframe {
	height: 100%;
}