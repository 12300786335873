.ant-menu {
	color: var(--color-text);
}
.MenuBar > .ant-menu {
	background: none;
	border: none;
	line-height: normal;
}
.MenuBar > .ant-menu > li {
	padding: 0;
	margin: 0 8px;
}
.MenuBar > .ant-menu > li:after {
	display: none !important;
}
.MenuBar > .ant-menu > li span > a {
	padding: 10px 12px;
	display: block;
	font-size: 18px;
	color: var(--color-text);
}
body.PageHome .MenuBar > .ant-menu > li span > a {
	color: #fff;
	text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.MenuBar > .ant-menu > li span > a:hover,
.MenuBar > .ant-menu > li.ant-menu-submenu-selected span > a,
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-sub li.ant-menu-item-selected {
	color: var(--color-secondary);
	text-decoration: none !important;
}
body.PageHome .MenuBar > .ant-menu > li span > a:hover,
.AppMenuDrop .ant-dropdown-menu.ant-dropdown-menu-vertical > li span > a:hover {
	color: var(--color-primary) !important;
	text-decoration: none !important;
}
.MenuBar > .ant-menu > li.ant-menu-item-selected > span > a,
.MenuBar > .ant-dropdown-trigger.ant-dropdown-open {
	color: var(--color-secondary);
	border-radius: 8px;
	text-decoration: none !important;
}
body.PageHome .MenuBar > .ant-menu > li.ant-menu-item-selected > span > a {
	color: var(--color-primary);
}
.MenuBar > .ant-menu > .ant-menu-item-selected a:hover,
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu > .ant-menu-item-selected a:hover {
	background: none !important;
	text-decoration: none !important;
}
.MenuBar > .ant-dropdown-trigger {
	color: var(--color-text);
	display: inline-block;
	padding: 10px 10px 3px;
	background: none;
	border: none;
	cursor: pointer;
}
.MenuBar > .ant-dropdown-trigger svg {
	width: 20px;
	height: 20px;
}
.MenuBar > .ant-dropdown-trigger:hover svg path {
	fill: var(--color-secondary);
}
body.PageHome .MenuBar > .ant-dropdown-trigger svg path {
	fill: #fff;
}
body.PageHome .MenuBar > .ant-dropdown-trigger:hover svg path {
	fill: var(--color-primary);
}
.AppMenuDrop .ant-dropdown-menu.ant-dropdown-menu-vertical {
	padding: 0;
}
.AppMenuDrop .ant-dropdown-menu.ant-dropdown-menu-vertical > li span > a {
	padding: 6px 8px;
	display: block;
	font-weight: 300;
	color: var(--color-text);
}
.AppMenuDrop .ant-dropdown-menu.ant-dropdown-menu-vertical > li span > a:hover {
	color: var(--color-secondary) !important;
}
body.PageHome .AppMenuDrop .ant-dropdown-menu.ant-dropdown-menu-vertical > li span > a:hover {
	color: var(--color-primary) !important;
}
body.PageHome .AppMenuDrop .ant-dropdown-menu.ant-dropdown-menu-vertical > li span > a {
	color: #fff;
}
body.PageHome .AppMenuDrop ul {
	background: rgba(0, 0, 0, 0.5) !important;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup .ant-dropdown-menu.ant-dropdown-menu-sub li a {
	font-weight: 300;
	color: var(--color-text);
}
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-sub li:not(.ant-menu-item-group):hover,
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-sub li.ant-menu-item-selected {
	background: rgba(0, 0, 0, 0.06);
}
.AppMenuDrop .ant-dropdown-menu.ant-dropdown-menu-vertical > li > div {
	display: flex;
	align-items: center;
}
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-sub li.ant-menu-item-group .ant-menu-item-group-title {
	font-weight: 400;
	color: #777;
	background: rgba(0, 0, 0, 0.04);
}