@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;600&display=swap');
:root {
  --color-primary: #E8B71A;
  --color-primary-rgb: 232, 183, 26;
  --color-secondary: #7F0000;
  --color-text: #667085;
  --color-text-rgb: 102, 112, 133;
  --color-bg: #F8FAFB;
  --custom-font: "Fira Sans";
  --color-success: #52C41A;
  --color-warning: #EC4C26;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background: var(--color-bg) !important;
  font-family: var(--custom-font) !important;
  color: var(--color-text) !important;
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 30px;
}
body p,
body a {
  font-size: 18px;
}
body * {
  box-sizing: border-box;
  font-family: var(--custom-font) !important;
}
h1, h2, h3, h4, h5, h6 {
  font-family: var(--custom-font) !important;
  font-weight: 500 !important;
}
a {
  color: var(--color-primary);
  text-decoration: none !important;
}
.mt-0 {
	margin-top: 0 !important;
}
.mt-20 {
	margin-top: 20px !important;
}
.mt-25 {
	margin-top: 25px !important;
}
.mt-30 {
	margin-top: 30px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mt-50 {
	margin-top: 50px !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-8 {
	margin-bottom: 8px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-25 {
	margin-bottom: 25px !important;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.mb-40 {
	margin-bottom: 40px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}