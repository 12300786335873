.InfoMenu {
	margin: 30px auto 0;
	max-width: 760px;
	border-radius: 20px;
}
.InfoMenu .ant-card-body {
	padding: 0 !important;
}
.InfoMenu ul {
	margin: 0 !important;
	padding: 0;
	list-style: none;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
}
.InfoMenu ul li {
	width: 100%;
	padding: 0;
	margin: 0 1px 0 0 !important;
	text-align: left;
}
.InfoMenu ul li:first-child {
	border-radius: 18px 18px 0 0;
}
.InfoMenu ul li:last-child {
	border-radius: 0 0 18px 18px;
	margin-right: 0 !important;
}
@media screen and (min-width: 768px) {
	.InfoMenu ul {
		flex-direction: row;
	}
	.InfoMenu ul li {
		width: 33.33%;
	}
	.InfoMenu ul li:first-child {
		border-radius: 18px 0 0 18px;
	}
	.InfoMenu ul li:last-child {
		border-radius: 0 18px 18px 0;
	}
}
.InfoMenu ul li {
	background: rgba(0, 0, 0, 0.6);
}
.InfoMenu ul li,
.InfoMenu ul li a {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	color: #fff;
	font-weight: 400;
	min-height: 75px;
	font-size: 18px;
}
.InfoMenu ul li a {
	width: 100%;
	border-radius: 18px;
}
.InfoMenu ul li.active,
.InfoMenu ul li:hover,
.InfoMenu ul li.active a,
.InfoMenu ul li:hover a {
	background: rgba(0, 0, 0, 0.8);
	color: var(--color-primary);
	text-decoration: none;
}
.InfoMenu ul li svg path {
	fill: rgba(255,255,255, 0.8);
}
.InfoMenu ul li.active svg path,
.InfoMenu ul li:hover svg path {
	fill: var(--color-primary);
}
.InfoMenu > ul li:hover {
    cursor: pointer;
}