.AppHeader {
	width: 100%;
	height: 100px;
	position: relative;
	z-index: 9;
}
.AppHeader .ant-btn {
  border: none;
	color: var(--color-text);
	box-shadow: none;
	display: flex;
	justify-content: center;
	align-items: center;
}
.AppHeader .ant-btn:hover {
	color: var(--color-primary) !important;
	background: var(--color-bg);
}
.Home {
	margin-top: -100px;
}
.AppHeader .AppContainer {
	padding: 25px 20px;
}
.AppHeader .AppLogo {
	display: inline-flex;
}
@media screen and (max-width: 767px) {
	.AppHeader {
		height: 80px;
	}
	.AppHeader .AppContainer {
		padding: 19px 20px;
	}
	.AppHeader .AppLogo img {
		height: 42px;
	}
}
body.PageHome .AppHeader .AppLogo img {
	display: none;
}
body.PageHome .AppHeader .AppLogo img.AppLogoLight {
	display: block !important;
}